<template>
  <div class="basic-detail-page">
    <div class="title">
      <span style="margin-right:16px">{{ techServiceBasic.organName }}</span>
      <el-button v-if="ifEdit == 1" class="zwx-button zwx-button-icontext-28" icon="el-icon-edit" @click="edit">编辑</el-button>
    </div>
    <hr class="hr" />
    <el-row style="padding:20px 0px;">
      <el-col :span="8">
        <el-descriptions class="basic-descriptions" :column="1">
          <el-descriptions-item label="社会信用代码" content-class-name="tech-value-class">{{ techServiceBasic.creditCode }}</el-descriptions-item>
          <el-descriptions-item label="机构类型">{{ techServiceBasic.serviceSortName }}</el-descriptions-item>
          <el-descriptions-item label="注册地区">{{ techServiceBasic.zoneName }}</el-descriptions-item>
          <el-descriptions-item label="注册地址">{{ techServiceBasic.workAddr }}</el-descriptions-item>
        </el-descriptions>
      </el-col>
      <el-col :span="8">
        <el-descriptions class="basic-descriptions" :column="1">
          <el-descriptions-item label="法定联系人">{{ techServiceBasic.legalPerson }}</el-descriptions-item>
          <el-descriptions-item label="联系电话">{{ techServiceBasic.telephone }}</el-descriptions-item>
          <el-descriptions-item label="联系人">{{ techServiceBasic.linkMan }}</el-descriptions-item>
          <el-descriptions-item label="联系人手机">{{ $zwxSm.sm4JsDecrypt(techServiceBasic.linkMobile) }}</el-descriptions-item>
        </el-descriptions>
      </el-col>
      <el-col :span="8" class="basic-descriptions last-basic-descriptions">
        <el-descriptions :column="1">
          <el-descriptions-item label="电子邮箱">{{ techServiceBasic.email }}</el-descriptions-item>
          <el-descriptions-item label="公司简介">
            <div :class="imgClass(techServiceBasic.introduceName)"></div>
            <div class="ms-folder-content pointer" @click="openFilePreview(techServiceBasic.introduceName, techServiceBasic.introducePath)">
              {{ techServiceBasic.introduceName }}
            </div>
          </el-descriptions-item>
          <el-descriptions-item label="营业执照">
            <div :class="imgClass(techServiceBasic.licenseName)"></div>
            <div class="ms-folder-content pointer" @click="openFilePreview(techServiceBasic.licenseName, techServiceBasic.licensePath)">
              {{ techServiceBasic.licenseName }}
            </div>
          </el-descriptions-item>
          <el-descriptions-item label="员工证明">
            <div :class="imgClass(techServiceBasic.employerName)"></div>
            <div class="ms-folder-content pointer" @click="openFilePreview(techServiceBasic.employerName, techServiceBasic.employerPath)">
              {{ techServiceBasic.employerName }}
            </div>
          </el-descriptions-item>
        </el-descriptions>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'TechBasicDetail',
  props: {
    techServiceBasic: { type: Object, default: () => {} },
    ifEdit: { type: Number, default: 2 },
  },
  data() {
    return {}
  },
  created() {},
  mounted() {},
  methods: {
    edit() {
      this.$router.push({ name: 'TechServiceBasicEdit', params: { rid: this.techServiceBasic.rid } })
    },
    imgClass(fileName) {
      if (fileName && fileName.lastIndexOf('jpeg') >= 0) {
        return 'basic-img-div-jpg'
      }
      return fileName && (fileName.lastIndexOf('jpg') >= 0 || fileName.lastIndexOf('pdf') >= 0 || fileName.lastIndexOf('png') >= 0) ? 'basic-img-div-' + fileName.substring(fileName.lastIndexOf('.') + 1) : ''
    },
    /**
     * 单个文件预览
     */
    openFilePreview(fileName, filePath) {
      let previewFileList = []
      let item = {
        fileName: fileName,
        filePath: filePath,
      }
      previewFileList.push(item)
      top.postMessage(
        {
          handlerType: 'previewFile',
          params: {
            fileList: previewFileList,
            index: 0,
          },
        },
        '*'
      )
    },
  },
}
</script>

<style lang="less" scoped>
.basic-detail-page {
  margin: 0 30px;
  .title {
    margin: 2px 0px 8px 0;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
    font-family: Harmony Medium;
    font-weight: 500;
    text-align: left;
    color: #191919;
    display: flex;
    align-items: center;
  }
}

.hr {
  height: 1px;
  border: 1px dashed #d5d9e0;
}
</style>

<style lang="less">
.basic-descriptions .el-descriptions-item__content {
  color: #191919;
}
.pointer {
  cursor: pointer;
}

.basic-img-div-pdf {
  width: 16px;
  height: 16px;
  top: 3px;
  left: -2px;
  position: relative;
  background: url('~@/assets/ico/file/icon_pdf.svg') left 0px no-repeat;
}
.basic-img-div-jpg {
  width: 16px;
  height: 16px;
  top: 3px;
  left: -2px;
  position: relative;
  background: url('~@/assets/ico/file/icon_jpg.svg') left 0px no-repeat;
}
.basic-img-div-png {
  width: 16px;
  height: 16px;
  top: 3px;
  left: -2px;
  position: relative;
  background: url('~@/assets/ico/file/icon_jpg.svg') left 0px no-repeat;
}
</style>
