<template>
  <div>
    <el-descriptions class="margin-style other-basic-descriptions" :column="4">
      <template slot="title">
        {{ tdTechServiceCertificationExp.serviceSortName }}
        <el-tag :type="tdTechServiceCertificationExp.operationStatus ? 'success' : 'danger'" size="mini" style="margin-left:12px;font-size: 12px;">{{ tdTechServiceCertificationExp.operationStatus ? '正常' : '注销' }}</el-tag>
        <el-tag :type="tdTechServiceCertificationExp.ifPublish ? 'success' : 'info'" size="mini" style="margin-left:12px;font-size: 12px;">{{ tdTechServiceCertificationExp.ifPublish ? '公示中' : '未公示' }}</el-tag>
        <el-tag v-if="tdTechServiceCertificationExp.unitLevel" type="warning" size="mini" style="margin-left:12px;font-size: 12px;">{{ tdTechServiceCertificationExp.unitLevel == 1 ? '省级' : tdTechServiceCertificationExp.unitLevel == 2 ? '地市级' : tdTechServiceCertificationExp.unitLevel == 3 ? '区县级' : '' }}</el-tag>
        <el-tag v-if="tdTechServiceCertificationExp.levelNo" type="warning" size="mini" style="margin-left:12px;font-size: 12px;">{{ tdTechServiceCertificationExp.levelNo == '1001' ? '甲级' : tdTechServiceCertificationExp.levelNo == '1002' ? '乙级' : tdTechServiceCertificationExp.levelNo == '1003' ? '丙级' : '' }}</el-tag>
        <el-button v-if="ifEdit == 1" class="zwx-button zwx-button-icontext-24" style="margin-left:12px;" icon="el-icon-edit" @click="edit">编辑</el-button>
        <el-button v-if="ifEdit == 1" class="zwx-button zwx-button-icontext-24 el-button--danger" style="margin-left:12px;" icon="el-icon-delete" @click="$system.msgbox('', '提示', '确定要删除吗？', '确定', '取消', delRecord)">删除</el-button>
      </template>
      <el-descriptions-item label="证书编号">{{ tdTechServiceCertificationExp.certificateNo }}</el-descriptions-item>
      <el-descriptions-item label="发证日期">{{ $system.formatDate(tdTechServiceCertificationExp.certificateDate, 'YYYY-MM-DD') }}</el-descriptions-item>
      <el-descriptions-item label="失效日期">{{ $system.formatDate(tdTechServiceCertificationExp.invalidDate, 'YYYY-MM-DD') }}</el-descriptions-item>
      <el-descriptions-item v-if="tdTechServiceCertificationExp.logoutDate" label="注销日期">{{ $system.formatDate(tdTechServiceCertificationExp.logoutDate, 'YYYY-MM-DD') }}</el-descriptions-item>
    </el-descriptions>
    <hr class="hr-dashed hr-width-96" />
    <div style="padding-left:30px;padding-top:18px;display: flex;">
      <div style="font-size: 14px;line-height: 18px;">服务项目：</div>
      <div style="font-size: 14px;line-height: 18px;max-width:80%">
        {{ tdTechServiceCertificationExp.serviceNames }}
      </div>
    </div>
    <div style="padding-left:30px;padding-top:18px;display: flex;">
      <div style="font-size: 14px;line-height: 14px;">附件资料：</div>
      <div v-if="tdTechServiceCertificationExp.tdTechServiceCertificationAnnexExps.length > 0 && tdTechServiceCertificationExp.tdTechServiceCertificationAnnexExps[0].annexList">
        <div v-for="(item, index) in tdTechServiceCertificationExp.tdTechServiceCertificationAnnexExps[0].annexList" :key="index" style="font-size: 14px;line-height: 14px;color:#2a50d7 !important;cursor:pointer;margin-bottom:16px;" :style="index == tdTechServiceCertificationExp.tdTechServiceCertificationAnnexExps[0].annexList.length - 1 ? 'margin-bottom:0;' : ''">
          <div @click="openFilePreview(item.annexName, item.annexPath)" style="display: inline-block;">
            <span :class="imgClass(item.annexName)" style="display:inline-block"></span>
            {{ item.annexName }}
          </div>
        </div>
      </div>
    </div>
    <!-- <hr class="hr-solid hr-width-96" /> -->
  </div>
</template>

<script>
export default {
  name: 'TechCertificationDetail',
  props: {
    tdTechServiceCertificationExp: {
      type: Object,
      default: () => {},
    },
    ifEdit: { type: Number, default: 2 },
  },
  data() {
    return {
      api: this.$store.state.api,
      users: this.$store.state.users,
      rid: this.$route.params.rid,
      // maxLength: 0,
    }
  },
  created() {
    // if (this.tdTechServiceCertificationExp.tdTechServiceCertificationAnnexExps && this.tdTechServiceCertificationExp.tdTechServiceCertificationAnnexExps.length > 0) {
    //   this.tdTechServiceCertificationExp.tdTechServiceCertificationAnnexExps.forEach(element => {
    //     const propLength = this.calStrLength(element.annexCodeName.toString())
    //     this.maxLength = this.maxLength > propLength ? this.maxLength : propLength
    //   })
    // }
  },
  mounted() {},
  activated() {},
  computed: {},
  methods: {
    // 点击编辑
    edit() {
      this.$emit('editDialog', this.tdTechServiceCertificationExp)
    },
    // 点击删除
    delRecord() {
      this.$emit('delRecord', this.tdTechServiceCertificationExp)
    },
    delRecordSuccess(data) {
      if (data.type === '00') {
        this.$system.notify('成功', data.mess, 'success')
        this.search(this.msForm.currentPage)
      } else if (data.type === '99') {
        this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
      } else {
        this.$system.notify('错误', data.mess, 'error')
      }
      this.$emit('loading', false)
    },
    imgClass(fileName) {
      return fileName && (fileName.lastIndexOf('jpg') >= 0 || fileName.lastIndexOf('pdf') >= 0 || fileName.lastIndexOf('jpeg') >= 0) ? 'img-div-' + fileName.substring(fileName.lastIndexOf('.') + 1) : ''
    },
    //样式处理
    styleFormat(str) {
      return 'padding-left:' + (this.maxLength - this.calStrLength(str)) + 'px'
    },
    /**
     * 计算字符串长度
     * @param {字符串} str
     */
    calStrLength(str) {
      let length = 0
      const document = this.$system.windowTop().document
      let html = document.createElement('span')
      const className = 'ms-cal-str-length' + this.$system.uuid()
      html.innerText = str
      html.className = className
      html.style = 'font-size: 14px; font-weight: 400; font-family: PingFangSC, PingFangSC-Regular;'
      document.querySelector('body').appendChild(html)
      length = document.querySelector('.' + className).offsetWidth + 2
      document.querySelector('.' + className).remove()
      return length
    },
    /**
     * 单个文件预览
     */
    openFilePreview(fileName, filePath) {
      let previewFileList = []
      let item = {
        fileName: fileName,
        filePath: filePath,
      }
      previewFileList.push(item)
      this.openFilePreview1(previewFileList, 0)
    },
    imgClass(fileName) {
      if (fileName && fileName.lastIndexOf('jpeg') >= 0) {
        return 'basic-img-div-jpg'
      }
      return fileName && (fileName.lastIndexOf('jpg') >= 0 || fileName.lastIndexOf('pdf') >= 0 || fileName.lastIndexOf('png') >= 0) ? 'basic-img-div-' + fileName.substring(fileName.lastIndexOf('.') + 1) : ''
    },
    /**
     * 多个文件预览
     */
    openFilePreview1(fileList, index) {
      top.postMessage(
        {
          handlerType: 'previewFile',
          params: {
            fileList: fileList,
            index: index,
          },
        },
        '*'
      )
    },
  },
}
</script>

<style lang="less" scoped>
.margin-style {
  padding-left: 30px;
  padding-top: 15px;
  /deep/.el-descriptions__header {
    margin-bottom: 16px !important;
  }
}
.hr-width-96 {
  width: 96%;
  margin: 0 auto 0 30px;
}
/deep/.min-width {
  min-width: 210px;
}
</style>

<style lang="less"></style>
