<template>
  <div class="container">
    <div class="upload">
      <div>
        <input type="file" ref="fileUpload" @change="fileChange($event)" style="display:none" />
        <el-button v-if="!showProgress" type="file" class="zwx-button zwx-button-text-26" icon="el-icon-upload2" @click="clickFileUpload">{{ $zwxBase.verifyIsBlank(videoId) ? '上传' : '重新上传' }}</el-button>
        <!--        <div class="icon-delete-class" style="color: red;" v-if="$zwxBase.verifyIsNotBlank(videoId)">
          <i style="cursor: pointer;" class="el-icon-delete"
             @click="removeVideo()"></i>
        </div>-->
        <!--        <span class="progress" >上传进度: <i v-if="$zwxBase.verifyIsBlank(videoId)" id="auth-progress">{{authProgress}}</i> <i v-if="$zwxBase.verifyIsNotBlank(videoId)" id="auth-progress">100</i> %</span>-->
        <span class="progress" v-if="showProgress">
          <el-progress class="post-process" :stroke-width="8" style="width: 250px;margin-top: 7px;" :percentage="authProgress"></el-progress>
          <!--          <i v-if="$zwxBase.verifyIsBlank(videoId)" id="auth-progress">{{authProgress}}</i> <i v-if="$zwxBase.verifyIsNotBlank(videoId)" id="auth-progress">100</i> %-->
        </span>
        <!--        <label class="status">上传状态: <span>{{statusText}}</span></label>-->
      </div>
      <div class="upload-type">
        <!--        <button @click="authUpload" :disabled="uploadDisabled">开始上传</button>-->
        <!--        <button @click="pauseUpload" :disabled="pauseDisabled">暂停</button>
                <button :disabled="resumeDisabled" @click="resumeUpload">恢复上传</button>-->
        <!--        <span class="progress">上传进度: <i v-if="$zwxBase.verifyIsBlank(videoId)" id="auth-progress">{{authProgress}}</i> <i v-if="$zwxBase.verifyIsNotBlank(videoId)" id="auth-progress">100</i> %</span>-->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AliUploadVideo',
  model: {
    prop: 'value',
    event: 'propEvent',
  },
  props: {
    value: { String, default: '' },
    timeout: { type: Number, default: 60000 }, // 请求过期时间，单位：毫秒
    partSize: { type: Number, default: 1048576 }, // 分片大小，单位：bit，1kb=8192bit，默认128kb
    parallel: { type: Number, default: 5 }, // 上传分片数
    retryCount: { type: Number, default: 3 }, // 网络失败重试次数
    retryDuration: { type: Number, default: 2 }, // 重试间隔
  },
  data() {
    return {
      showProgress: false,
      api: this.$store.state.api,
      region: 'cn-shanghai',
      //不能为空，但是要存在，否则阿里接口调用会报错
      userId: '1',
      file: null,
      authProgress: 0,
      uploadDisabled: true,
      resumeDisabled: true,
      pauseDisabled: true,
      uploader: null,
      statusText: '',
      videoId: '',
    }
  },
  mounted() { },
  watch: {
    videoId(val) {
      this.$emit('propEvent', val)
    },
    value: {
      handler(v) {
        this.videoId = v
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    clickFileUpload() {
      this.$refs.fileUpload.click()
    },
    fileChange(e) {
      this.file = e.target.files[0]
      if (!this.file) {
        alert('请先选择需要上传的文件!')
        return
      }
      var Title = this.file.name
      var userData = '{"Vod":{}}'
      if (this.uploader) {
        this.uploader.stopUpload()
        this.authProgress = 0
        this.statusText = ''
      }
      this.uploader = this.createUploader()
      this.uploader.addFile(this.file, null, null, null, userData)
      if (this.uploader !== null) {
        this.uploader.startUpload()
        //this.uploadDisabled = true
        //this.pauseDisabled = false
      }
      //this.uploadDisabled = false
      //this.pauseDisabled = true
      //this.resumeDisabled = true
      //不设置若删除文件则不会再次触发
      e.target.value = ''
    },
    authUpload() {
      // 然后调用 startUpload 方法, 开始上传
      if (this.uploader !== null) {
        this.uploader.startUpload()
        //this.uploadDisabled = true
        //this.pauseDisabled = false
      }
    },
    // 暂停上传
    pauseUpload() {
      if (this.uploader !== null) {
        this.uploader.stopUpload()
        this.resumeDisabled = false
        this.pauseDisabled = true
      }
    },
    // 恢复上传
    resumeUpload() {
      if (this.uploader !== null) {
        this.uploader.startUpload()
        this.resumeDisabled = true
        this.pauseDisabled = false
      }
    },
    createUploader(type) {
      let self = this
      let uploader = new AliyunUpload.Vod({
        timeout: self.timeout || 60000,
        partSize: Math.round(self.partSize || 1048576),
        parallel: self.parallel || 5,
        retryCount: self.retryCount || 3,
        retryDuration: self.retryDuration || 2,
        region: self.region,
        userId: self.userId,
        // 添加文件成功
        addFileSuccess: function (uploadInfo) {
          //添加文件成功后删除之前的文件
          // if (self.$zwxBase.verifyIsNotBlank(self.videoId)) {
          //   self.removeVideo()
          // }
          //self.uploadDisabled = false
          //self.resumeDisabled = false
          //self.statusText = '添加文件成功, 等待上传...'
        },
        // 开始上传
        onUploadstarted: function (uploadInfo) {
          const readyUploadfile = uploadInfo.file
          let data = { fileName: readyUploadfile.name, title: readyUploadfile.name.indexOf('.') >= 0 ? readyUploadfile.name.substring(0, readyUploadfile.name.indexOf('.')) : readyUploadfile.name }
          if (!uploadInfo.videoId) {
            self.$system.get(
              self.api + '/training/platform/getUploadAuth-1',
              data,
              true,
              true,
              data => {
                if (data.type === '00') {
                  const uploadVideoResponseBody = data.uploadVideoResponseBody
                  uploader.setUploadAuthAndAddress(uploadInfo, uploadVideoResponseBody.uploadAuth, uploadVideoResponseBody.uploadAddress, uploadVideoResponseBody.videoId)
                } else {
                  self.$system.notify('失败', data.mess, 'error')
                  self.handlerDataForError()
                }
              },
              this.searchError
            )
            self.statusText = '文件开始上传...'
            self.showProgress = true
          } else {
            // 如果videoId有值，根据videoId刷新上传凭证
            self.$system.get(
              self.api + '/training/platform/refreshUploadVideoAuth-1',
              data,
              true,
              true,
              data => {
                if (data.type === '00') {
                  const uploadVideoResponseBody = data.uploadVideoResponseBody
                  uploader.setUploadAuthAndAddress(uploadInfo, uploadVideoResponseBody.uploadAuth, uploadVideoResponseBody.uploadAddress, uploadVideoResponseBody.videoId)
                }
              },
              this.searchError
            )
          }
        },
        // 文件上传成功
        onUploadSucceed: function (uploadInfo) {
          self.statusText = '文件上传成功!'
        },
        // 文件上传失败
        onUploadFailed: function (uploadInfo, code, message) {
          self.statusText = '文件上传失败!'
        },
        // 取消文件上传
        onUploadCanceled: function (uploadInfo, code, message) {
          self.statusText = '文件已暂停上传'
        },
        // 文件上传进度，单位：字节, 可以在这个函数中拿到上传进度并显示在页面上
        onUploadProgress: function (uploadInfo, totalSize, progress) {
          let progressPercent = Math.ceil(progress * 100)
          self.authProgress = progressPercent
          self.statusText = '文件上传中...'
        },
        // 上传凭证超时
        onUploadTokenExpired: function (uploadInfo) {
          // 上传大文件超时, 如果是上传方式一即根据 UploadAuth 上传时
          // 需要根据 uploadInfo.videoId 调用刷新视频上传凭证接口(https://help.aliyun.com/document_detail/55408.html)重新获取 UploadAuth
          // 然后调用 resumeUploadWithAuth 方法, 这里是测试接口, 所以我直接获取了 UploadAuth
          const readyUploadfile = uploadInfo.file
          let data = { fileName: readyUploadfile.name, title: readyUploadfile.name.indexOf('.') >= 0 ? readyUploadfile.name.substring(0, readyUploadfile.name.indexOf('.')) : readyUploadfile.name }
          self.$system.get(
            self.api + '/training/platform/refreshUploadVideoAuth-1',
            data,
            true,
            true,
            data => {
              if (data.type === '00') {
                const uploadVideoResponseBody = data.uploadVideoResponseBody
                uploader.setUploadAuthAndAddress(uploadInfo, uploadVideoResponseBody.uploadAuth, uploadVideoResponseBody.uploadAddress, uploadVideoResponseBody.videoId)
              }
            },
            this.searchError
          )
          self.statusText = '文件超时...'
        },
        // 全部文件上传结束
        onUploadEnd: function (uploadInfo) {
          self.videoId = uploader._uploadList[0].videoId
          self.showProgress = false
          self.statusText = '文件上传完毕'
        },
      })
      return uploader
    },
    searchError(data) {
    },
    //删除阿里云视频
    removeVideo() {
      let data = { videoId: this.videoId }
      this.$system.get(
        this.api + '/training/platform/removeVideo-1',
        data,
        true,
        true,
        data => {
          if (data.type === '00') {
          } else {
            //this.$system.notify('失败', data.mess, 'error')
          }
          this.videoId = null
        },
        this.searchError
      )
    },
    removeVideoByVideo(videoId) {
      let data = { videoId: videoId }
      this.$system.get(
        this.api + '/training/platform/removeVideo-1',
        data,
        true,
        true,
        data => {
          if (data.type === '00') {
          } else {
          }
          this.videoId = null
        },
        this.searchError
      )
    },
    // 上传失败后处理数据
    handlerDataForError() {
      this.showProgress = false
      this.videoId = null
      // 清空阿里云上传实体
      this.uploader = null
    },
  },
}
</script>
