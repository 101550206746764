var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "upload" }, [
      _c(
        "div",
        [
          _c("input", {
            ref: "fileUpload",
            staticStyle: { display: "none" },
            attrs: { type: "file" },
            on: {
              change: function($event) {
                return _vm.fileChange($event)
              }
            }
          }),
          !_vm.showProgress
            ? _c(
                "el-button",
                {
                  staticClass: "zwx-button zwx-button-text-26",
                  attrs: { type: "file", icon: "el-icon-upload2" },
                  on: { click: _vm.clickFileUpload }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$zwxBase.verifyIsBlank(_vm.videoId)
                        ? "上传"
                        : "重新上传"
                    )
                  )
                ]
              )
            : _vm._e(),
          _vm.showProgress
            ? _c(
                "span",
                { staticClass: "progress" },
                [
                  _c("el-progress", {
                    staticClass: "post-process",
                    staticStyle: { width: "250px", "margin-top": "7px" },
                    attrs: { "stroke-width": 8, percentage: _vm.authProgress }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("div", { staticClass: "upload-type" })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }