<template>
  <div class="textBook_manage_edit" style="padding-bottom: 20px;">
    <el-form class="zwx-form edit-form" key="msForm" ref="msForm" :model="msForm" :rules="msFormRules" label-position="right" @submit.native.prevent>
      <div class="edit-row">
        <el-form-item label-width="140px" label="教材名称：" prop="fileName">
          <el-input style="width:537px !important" class="zwx-input" v-model="msForm.fileName" placeholder="请输入教材名称" clearable maxlength="200" />
        </el-form-item>
      </div>
      <div class="edit-row">
        <el-form-item label="分类：" label-width="140px" prop="categoryCode">
          <el-radio-group class="zwx-radio-group" style="min-width:68px!important" v-model="msForm.categoryCode" @change="changeCategoryCode">
            <el-radio class="zwx-radio" :label="1">教材</el-radio>
            <el-radio class="zwx-radio" :label="2">相关资料</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="edit-row" v-if="msForm.categoryCode == 1">
        <el-form-item label="专家：" label-width="140px">
          <el-select class="zwx-select expertIdList" popper-class="zwx-select-popper" style="width:537px !important;height:40px !important" v-model="msForm.expertUid" filterable placeholder="请选择" clearable>
            <el-option v-for="item in expertNameList" :key="item.uuid" :label="item.expertName" :value="item.uuid" style="height: 40px!important;">
              <span style="float: left;font-size: 13px;margin-top:-7px;">{{ item.expertName }}</span>
              <span style="float: left;display:block;position:absolute;margin-top:10px;font-size: 10px">职称:{{ item.professionalTittle }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ $zwxSm.sm4JsDecrypt(item.contact) }}</span>
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="edit-row" v-if="msForm.categoryCode == 1">
        <el-form-item label-width="140px" label="学时：" prop="classHour">
          <el-input style="width:537px !important" class="zwx-input" v-model="msForm.classHour" placeholder="请输入" clearable maxlength="9" @input="$validate.number($data['msForm'], 'classHour', -1, 1)" />
        </el-form-item>
      </div>
      <div class="edit-row" v-if="msForm.categoryCode == 2">
        <el-form-item label="文件类别：" label-width="140px" prop="fileType">
          <el-radio-group class="zwx-radio-group" style="min-width:68px!important" v-model="msForm.fileType" :disabled="fileTypeDis">
            <el-radio class="zwx-radio" :label="1">pdf</el-radio>
            <el-radio v-if="msForm.categoryCode == 1" class="zwx-radio" :label="2">视频</el-radio>
            <el-radio class="zwx-radio" :label="3">word</el-radio>
            <el-radio class="zwx-radio" :label="4">excel</el-radio>
            <el-radio class="zwx-radio" :label="5">ppt</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="edit-row" v-if="msForm.fileType != 2" key="fujian">
        <el-form-item label-width="140px" label="附件：" prop="annexList">
          <file-upload ref="annexList" @showFileList="showannexList" accept=".pdf,.PDF,.doc,.docx,.DOC,.DOCX,.xls,.xlsx,.XLS,.XLSX,.ppt,.pptx,.PPT,.PPTX" @success="annexFileSuccess" @error="fileUploadError" :size="50 * 1024 * 1024" sizeInfo="50M">
            <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-upload2" v-if="$zwxBase.verifyIsBlank(msForm.annexList)">
              上传
            </el-button>
          </file-upload>
          <div class="show-file" v-if="$zwxBase.verifyIsNotBlank(msForm.annexList)">
            <span @mouseenter="annexFileMouseenter" @mouseleave="annexFileMouseleave">
              <div style="display: flex;align-items:center;height: 32px">
                <div style="display: flex;align-items:center;color:#194DFF !important;cursor:pointer;" @click="openFilePreview(msForm.annexList, 0)">
                  <i :class="$zwxBase.dealfilePrefix(msForm.annexList[0].fileName)"></i>
                  <div class="name-hidden" style="max-width: 400px;color:#316CF5!important;margin-left:5px;">{{ msForm.annexList[0].fileName }}</div>
                </div>
                <div class="el-upload-list__item check" :style="msForm.annexList[0].check ? 'margin-left:5px;display:none;' : 'margin-left:5px;cursor:pointer'">
                  <i class="el-icon-upload-success el-icon-circle-check"></i>
                </div>
                <div class="check" :style="msForm.annexList[0].check ? 'margin-left:5px;cursor:pointer' : 'margin-left:5px;display:none;'">
                  <i class="el-icon-close" @click="delAnnexFile(0)"></i>
                </div>
              </div>
            </span>
          </div>
        </el-form-item>
      </div>
      <div class="edit-row" v-show="msForm.fileType == 2"  key="wenjian">
        <div>
          <el-form-item label-width="140px" label="阿里云点播地址：" prop="filePath" v-if="showFilePath&&config.videoPlayWay!=2">
            <!--          <el-input style='width:537px !important' class='zwx-input' v-model='msForm.filePath'
                    placeholder='请输入文件地址' clearable maxlength='200'/>-->
            <ali-upload-video ref="aliUpload" v-model="msForm.filePath"></ali-upload-video>
          </el-form-item>
        </div>
        <div class="flex-row" v-if="config.videoPlayWay!=1">
          <span style="font-size: 14px;color: #3A485E;padding-left: 10px;">上传本地服务器：</span>
          <el-popover placement="top" trigger="hover" content="建议上传100M以内的视频，若视频过大建议直接填写视频地址。">
            <i slot="reference" class="el-icon-info" style="color:#606266;margin-right:10px"></i>
          </el-popover>
          <el-form-item>
            <file-upload ref="localFileRef" accept=".mp4,.MP4" @showFileList="localFileShowFileList" @success="localFileUploadSuccess" :size="100 * 1024 * 1024" sizeInfo="100M">
              <span v-if="$zwxBase.verifyIsBlank(msForm.annexListLocal)" style="color:#0045f9">
                <i class="el-icon-upload2"></i>
                上传
              </span>
            </file-upload>

            <div class="show-file" v-if="msForm.localFilePath">
              <el-button class="zwx-button zwx-button-text-26" type="text" @click="openVideoPreview(msForm.localFilePath)">查看</el-button>
              <el-button v-if="msForm.localFilePath" class="zwx-button zwx-button-text-26" type="danger" @click="deletionLocalFile1(0)">删除</el-button>
            </div>
          </el-form-item>
        </div>
        <el-form-item label-width="140px" label="服务器存储地址：" prop="storePath" v-if="config.videoPlayWay!=1">
          <el-input style="width:537px !important" class="zwx-input" v-model="msForm.storePath" placeholder="请输入文件地址" clearable maxlength="200" />
        </el-form-item>
      </div>
    </el-form>
    <fixed-footer>
      <el-button class="zwx-button zwx-button-32" type="primary" @click="addOrModifyExamConfig" :disabled="saveButtonDisabled">保存</el-button>
    </fixed-footer>
    <base-dialog class="choose-topic-dialog" ref="chooseTopicDialogRef" title="选择题目" @determine="determineChooseTopicDialog" @cancel="cancelChooseTopicDialog" @close="cancelChooseTopicDialog"  >
      <div class="choose-topic-body">
        <video src="" id="video" ref="video" controls></video>
       
      </div>
    </base-dialog>
  </div>
</template>

<script>
import AliUploadVideo from './components/AliUploadVideo.vue'
import Hls from 'hls.js';
export default {
  name: 'MaterialManageEdit',
  components: { AliUploadVideo },
  data() {
    return {
      api: this.$store.state.api,
      file: this.$store.state.file,
      users: this.$store.state.users,
      type: this.$route.params.type,
      //是否显示学员类型、培训类型
      showFilePath: this.$zwxBase.verifyIsBlank(this.$route.meta.showFilePath) ? true : this.$route.meta.showFilePath,
      msForm: {
        rid: '',
        fileName: '',
        filePath: '',
        storePath: '',
        categoryCode: '',
        classHour: '',
        expertUid: '',
        fileType: '',
        annexList: [],
        localFileName: '',
        localFilePath: "",
        annexListLocal: []
      },
      fileTypeDis: false,
      expertNameList: [],
      msFormOld: null,
      msFormRules: {
        fileName: [{ required: true, message: '教材名称不能为空', trigger: ['change', 'blur'] }],
        classHour: [{ required: true, message: '课时不能为空', trigger: ['change', 'blur'] }],
        filePath: [{required: true, validator: this.validaterFilePath, trigger: ['change', 'blur']}],
        fileType: [{ required: true, message: '请选择文件类别', trigger: ['change', 'blur'] }],
        categoryCode: [{ required: true, message: '分类不能为空', trigger: ['change', 'blur'] }],
        annexList: [{ required: true, validator: this.validaterAnnexList, trigger: ['change', 'blur'] }],
        storePath:[{ required: true, message: '存储地址不能为空', trigger: ['change', 'blur'] }]
      },
      config: {},
    }
  },
  computed: {
    saveButtonDisabled() {
      var str = JSON.stringify(this.msForm) //序列化对象
      var newobj = JSON.parse(str) //还原
      if (this.$zwxBase.verifyIsNotBlank(newobj.annexList)) {
        newobj.annexList.forEach(item => {
          item.check = null
        })
      }
      if (JSON.stringify(newobj) !== this.msFormOld) {
        return false
      } else {
        return true
      }
    },
  },
  watch: {
    'msForm.filePath':{
      handler(newVal){
        console.log(newVal)
        if(newVal){
          this.$nextTick(() => {
              this.$refs['msForm'].clearValidate('filePath')
            })
        }
      },
      deep:true
    }
  },
  created() {
    if (this.type === 'add') {
      ; (this.msForm.categoryCode = 1), (this.msForm.fileType = 1)
    }
    this.$SimpleCodeTools.getConfig(data => {
      this.config = data
    })
  },
  mounted() {
    this.pageRouter()
    //获取专家集合
    this.getTrainingExpertListNoPage()
    this.changeCategoryCode()
  },

  methods: {
    //预览视频
    openVideoPreview(localFilePath) {
      if(localFilePath && localFilePath.indexOf('.m3u8')>0){
        // window.open(this.file+localFilePath)
        this.openVideo(this.file+localFilePath)
      }else{
         window.open(this.api + '/training/platform/getMaterialVideo-0?path=' + localFilePath)
      }
     
    },
    openVideo(url){
      this.$refs.chooseTopicDialogRef.show(true)
      this.$nextTick(()=>{
        this.watchViedo(url)
      })
    },
    determineChooseTopicDialog(){
      this.$refs.chooseTopicDialogRef.show(false)
    },
    cancelChooseTopicDialog(){
      this.$refs.chooseTopicDialogRef.show(false)
    },
    watchViedo(url){
      const video = this.$refs.video;
      if (Hls.isSupported()) {
        const hls = new Hls();
        hls.loadSource(url);  // 替换为实际的m3u8视频路径
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          video.play();
        });
      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        // 如果浏览器支持HLS格式，可以直接设置video元素的src属性
        video.src = url;
        video.addEventListener('loadedmetadata', () => {
          video.play();
        });
      }
    },
    /**
     * 服务器存储地址文件上传显示回调
     */
    localFileShowFileList(showList) {
      showList.forEach(item => {
        let flag = this.msForm.annexListLocal.some(e => e.fileName == item)
        if (flag) {
          return
        }
        this.msForm.annexListLocal.push({
          fileName: item,
          fileIconShow: this.$zwxBase.dealfilePrefix(item),
          check: false,
        })
      })
      if (showList && showList.length > 0) {
        this.generateFilesUidLocal()
      }
    },
    // 获取服务器存储地址文件的UID
    generateFilesUidLocal() {
      this.$emit('loading', true)
      let data = {
        folder: 'saas/' + this.config.domainPrefix + '/course/material',
      }
      this.$system.get(this.api + '/systematic/tools/getAnnexUploadUid-0', data, false, false, data => {
        if (data.type === '00') {
          this.$refs.localFileRef.submit(data.annexUploadUid)

        } else {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      })
    },
    /**
     * 服务器存储地址文件上传成功回调
     */
    localFileUploadSuccess(fileList) {
      // 文件集合
      if (this.$zwxBase.verifyIsNotBlank(this.msForm.annexListLocal)
        && this.$zwxBase.verifyIsNotBlank(fileList)) {
        for (let i in this.msForm.annexListLocal) {
          for (let j in fileList) {
            if (this.msForm.annexListLocal[i].fileName === fileList[j].fileName) {
              this.msForm.annexListLocal[i].filePath = fileList[j].filePath
              this.msForm.localFileName = this.$zwxBase.verifyIsNotBlank(this.msForm.annexListLocal) ? this.msForm.annexListLocal[0].fileName : null
              this.msForm.localFilePath = this.$zwxBase.verifyIsNotBlank(this.msForm.annexListLocal) ? this.msForm.annexListLocal[0].filePath : null
              this.msForm.storePath = this.$zwxBase.verifyIsNotBlank(this.msForm.annexListLocal) ? this.msForm.annexListLocal[0].filePath : null
            }
          }
        }
      }
      this.$emit('loading', false)
    },
    /**
     * 删除服务器存储地址文件
     */
    deletionLocalFile1(index) {
      let fileName = this.msForm.annexListLocal[index].fileName
      let filePath = this.msForm.annexListLocal[index].filePath
      this.msForm.annexListLocal.splice(index, 1)
      this.msForm.localFileName = ''
      this.msForm.localFilePath = ''
      this.msForm.storePath = ''
      if (this.$zwxBase.verifyIsBlank(filePath)) {
        this.$refs.localFileRef.deleteIndex(index)
      }
      let fileList = this.$refs.localFileRef.showFileList || []
      let count = 0
      fileList.forEach(item => {
        if (fileName === item) {
          count++
        }
      })
      for (let i = 0; i < count; i++) {
        for (let j in fileList) {
          if (fileName === fileList[j]) {
            this.$refs.localFileRef.deleteIndex(j)
            fileList = this.$refs.localFileRef.showFileList || []
            continue
          }
        }
      }
    },
    getTrainingExpertListNoPage() {
      this.$system.post(
        this.api + '/training/platform/getTrainingExpertListNoPage-1',
        {},
        true,
        true,
        data => {
          if (data.type === '00') {
            this.expertNameList = data.courseExpertList
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
          this.$emit('loading', false)
        },
        () => {
          this.$emit('loading', false)
          this.$system.notify('错误', '网络连接失败', 'error')
        }
      )
    },
    validaterFilePath(rule, value, callback) {
      if (this.msForm.fileType == 2) {
        if (this.$zwxBase.verifyIsNotBlank(this.msForm.filePath) || this.$zwxBase.verifyIsNotBlank(this.msForm.storePath)) {
          return callback()
        } else {
          return callback(new Error('文件地址不能为空'))
        }
      }
      return callback()
    },
    validaterAnnexList(rule, value, callback) {
      if (this.msForm.fileType != 2) {
        if (this.$zwxBase.verifyIsNotBlank(value)) {
          return callback()
        } else {
          return callback(new Error('附件不能为空'))
        }
      }
      return callback()
    },
    /**
     * 页面路由回调
     */
    pageRouter() {
      if (this.type === 'add') {
        this.$emit('breadcrumb', '添加', true)
        this.msFormOld = JSON.stringify(this.msForm)
      }
      if (this.type === 'edit') {
        this.$emit('breadcrumb', '编辑', true)
        this.getDetail(this.$route.params.row.uuid)
      }
    },
    /**
     * 保存或更新
     */
    addOrModifyExamConfig() {
      this.$refs['msForm'].validate(valid => {
        if (valid) {
          /*if(this.msForm.fileType!=2){
              this.generateFilesUid()
          }else{
            this.submit()
          }*/
          this.submit()
        } else {
          return false
        }
      })
    },
    submit() {
      if (this.msForm.categoryCode == 2) {
        this.msForm.classHour = ''
        this.msForm.expertUid = ''
      }
      //判断是否为视频信息
      this.removeAliyunVideo()
      if (this.msForm.fileType != 2) {
        this.msForm.filePath = this.msForm.annexList[0].filePath
      }
      let data = {
        ...this.msForm,
      }
      this.$system.postJson(
        this.api + '/training/platform/addOrModifyTeachingMaterial-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type == '00') {
            this.$system.notify('成功', '保存成功', 'success')
            this.$router.push({ name: 'MaterialManage', params: { fresh: true } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    /**
     * 获取详情
     */
    getDetail(uuid) {
      let data = {
        uuid,
      }
      this.$emit('loading', true)
      this.$system.get(
        this.api + '/training/platform/getTeachingMaterial-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type == '00') {
            this.msForm.uuid = data.teachingMaterial.uuid
            this.msForm.fileName = data.teachingMaterial.fileName
            this.msForm.categoryCode = data.teachingMaterial.categoryCode
            this.msForm.classHour = data.teachingMaterial.classHour
            this.msForm.expertUid = data.teachingMaterial.expertUid
            this.msForm.fileType = data.teachingMaterial.fileType
            this.msForm.annexList = []
            if (data.teachingMaterial.fileType == 2) {
              this.msForm.filePath = data.teachingMaterial.filePath
              this.msForm.storePath = data.teachingMaterial.storePath
              this.msForm.localFilePath = data.teachingMaterial.storePath
              this.msForm.annexListLocal = data.teachingMaterial.storePath ? [{ fileName: data.teachingMaterial.fileName, filePath: data.teachingMaterial.storePath }] : []
            }
            if (this.$zwxBase.verifyIsNotBlank(this.msForm.fileType) && this.msForm.fileType != 2) {
              this.msForm.annexList.push({
                fileName: data.teachingMaterial.fileName + data.teachingMaterial.filePath.substring(data.teachingMaterial.filePath.lastIndexOf('.')),
                filePath: data.teachingMaterial.filePath,
                check: false,
              })
            }

            if (this.$zwxBase.verifyIsNotBlank(this.msForm.annexList[0])) {
              var prefix = this.msForm.annexList[0].fileName.substring(this.msForm.annexList[0].fileName.lastIndexOf('.'))
              if (prefix === '.pdf' || prefix === '.PDF') {
                this.msForm.fileType = 1
              } else if (prefix === '.doc' || prefix === '.docx' || prefix === '.DOC' || prefix === '.DOCX') {
                this.msForm.fileType = 3
              } /*else if (prefix === '.mp4') {
                  this.msForm.fileType = 2
                }*/ else if (prefix === '.xls' || prefix === '.xlsx' || prefix === '.XLS' || prefix === '.XLSX') {
                this.msForm.fileType = 4
              } else if (prefix === '.ppt' || prefix === '.pptx' || prefix === '.PPT' || prefix === '.PPTX') {
                this.msForm.fileType = 5
              }
              this.fileTypeDis = true
            }
            this.msFormOld = JSON.stringify(this.msForm)
            var oldobj = JSON.parse(this.msFormOld)
            if (this.$zwxBase.verifyIsNotBlank(oldobj.annexList)) {
              oldobj.annexList.forEach(item => {
                item.check = null
              })
              this.msFormOld = JSON.stringify(oldobj)
            }
            this.$nextTick(() => {
              this.$refs['msForm'].clearValidate()
            })
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    /**
     * 资质附件添加上传文件回调
     */
    showannexList(showList) {
      this.msForm.annexList = []
      showList.forEach(item => {
        let flag = this.msForm.annexList.some(e => e.fileName == item)
        if (flag) {
          return
        }
        this.msForm.annexList.push({
          fileName: item,
          check: false,
        })
      })
      if (showList && showList.length > 0) {
        this.generateFilesUid()
      }
      this.$refs.msForm.validateField('annexList')

      if (this.$zwxBase.verifyIsNotBlank(this.msForm.annexList[0])) {
        var prefix = this.msForm.annexList[0].fileName.substring(this.msForm.annexList[0].fileName.lastIndexOf('.'))
        if (prefix === '.pdf' || prefix === '.PDF') {
          this.msForm.fileType = 1
        } else if (prefix === '.doc' || prefix === '.docx' || prefix === '.DOC' || prefix === '.DOCX') {
          this.msForm.fileType = 3
        } /*else if (prefix === '.mp4') {
          this.msForm.fileType = 2
        }*/ else if (prefix === '.xls' || prefix === '.xlsx' || prefix === '.XLS' || prefix === '.XLSX') {
          this.msForm.fileType = 4
        } else if (prefix === '.ppt' || prefix === '.pptx' || prefix === '.PPT' || prefix === '.PPTX') {
          this.msForm.fileType = 5
        }
        this.fileTypeDis = true
      }
    },
    /**
     * 获取文件上传uuid
     */
    generateFilesUid() {
      let data = {
        folder: 'saas/' + this.config.domainPrefix + '/course',
      }
      this.$emit('loading', true)
      this.$system.get(
        this.api + '/systematic/tools/getAnnexUploadUid-1',
        data,
        true,
        true,
        data => {
          if (data.type === '00') {
            this.$refs.annexList.submit(data.annexUploadUid)
            this.$emit('loading', false)
          } else {
            this.$emit('loading', false)
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        () => {
          this.$system.notify('错误', '网络连接失败', 'error')
        }
      )
    },
    /**
     * 资质附件上传成功回调
     */
    annexFileSuccess(filePathList) {
      if (this.$zwxBase.verifyIsNotBlank(this.msForm.annexList) && this.$zwxBase.verifyIsNotBlank(filePathList)) {
        for (let i in this.msForm.annexList) {
          for (let j in filePathList) {
            if (this.msForm.annexList[i].fileName === filePathList[j].fileName) {
              this.msForm.annexList[i].filePath = filePathList[j].filePath
            }
          }
        }
      }
      //this.submit()
    },
    /**
     * 文件上传失败回调
     */
    fileUploadError() {
      this.$system.notify('失败', '文件上传失败', 'error')
    },
    /**
     * 附件鼠标上移回调
     */
    annexFileMouseenter() {
      this.msForm.annexList[0].check = true
    },
    /**
     * 附件鼠标移除回调
     */
    annexFileMouseleave() {
      this.msForm.annexList[0].check = false
    },
    /**
     * 文件预览
     */
    openFilePreview(fileList, index) {
      top.postMessage(
        {
          handlerType: 'previewFile',
          params: {
            fileList: fileList,
            index: index,
          },
        },
        '*'
      )
    },
    /**
     * 删除附件
     */
    delAnnexFile(index) {
      this.$nextTick(() => {
        this.fileTypeDis = false
      })
      let fileName = this.msForm.annexList[index].fileName
      this.msForm.annexList.splice(index, 1)
      this.$refs.annexList.deleteIndex(index)
      let fileList = this.$refs.annexList.showFileList || []
      let count = 0
      fileList.forEach(item => {
        if (fileName === item) {
          count++
        }
      })
      for (let i = 0; i < count; i++) {
        for (let j in fileList) {
          if (fileName === fileList[j]) {
            this.$refs.annexList.deleteIndex(j)
            fileList = this.$refs.annexList.showFileList || []
            continue
          }
        }
      }
    },
    changeCategoryCode() {
      if (this.msForm.categoryCode == 1) {
        this.fileTypeDis = true
        this.msForm.fileType = 2
      } else {
        if (this.msForm.annexList.length > 0) {
          var prefix = this.msForm.annexList[0].fileName.substring(this.msForm.annexList[0].fileName.lastIndexOf('.'))
          if (prefix === '.pdf' || prefix === '.PDF') {
            this.msForm.fileType = 1
          } else if (prefix === '.doc' || prefix === '.docx' || prefix === '.DOC' || prefix === '.DOCX') {
            this.msForm.fileType = 3
          } else if (prefix === '.xls' || prefix === '.xlsx' || prefix === '.XLS' || prefix === '.XLSX') {
            this.msForm.fileType = 4
          } else if (prefix === '.ppt' || prefix === '.pptx' || prefix === '.PPT' || prefix === '.PPTX') {
            this.msForm.fileType = 5
          }
          this.fileTypeDis = true
        } else {
          this.msForm.fileType = 1
          this.fileTypeDis = false
        }
      }
    },
    //清空视频相关内容
    removeAliyunVideo() {
      if (this.$zwxBase.verifyIsNotBlank(this.msForm.fileType) && this.msForm.fileType != 2 && this.config.videoPlayWay!=2) {
        this.$refs.aliUpload.removeVideoByVideo(this.msForm.filePath)
        this.msForm.filePath = ''
      }
    },
  },
}
</script>

<style lang="less" scoped>
  .choose-topic-dialog .choose-topic-body {
  height: 363px;
  display: flex;
    justify-content: center;
    align-items: center;
}
.expertIdList {
  /deep/ .el-input__inner {
    width: 537px !important;
  }
  /deep/ .el-input {
    font-size: 16px;
    color: #b5bac2;
  }
}
#video{
  
  height: 360px;
}
.ms-select-popper .el-select-dropdown__list .el-select-dropdown__item {
  height: 40px !important;
}
</style>

<style lang="less"></style>
