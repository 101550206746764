var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-descriptions",
        {
          staticClass: "margin-style other-basic-descriptions",
          attrs: { column: 4 }
        },
        [
          _c(
            "template",
            { slot: "title" },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.tdTechServiceCertificationExp.serviceSortName) +
                  " "
              ),
              _c(
                "el-tag",
                {
                  staticStyle: { "margin-left": "12px", "font-size": "12px" },
                  attrs: {
                    type: _vm.tdTechServiceCertificationExp.operationStatus
                      ? "success"
                      : "danger",
                    size: "mini"
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.tdTechServiceCertificationExp.operationStatus
                        ? "正常"
                        : "注销"
                    )
                  )
                ]
              ),
              _c(
                "el-tag",
                {
                  staticStyle: { "margin-left": "12px", "font-size": "12px" },
                  attrs: {
                    type: _vm.tdTechServiceCertificationExp.ifPublish
                      ? "success"
                      : "info",
                    size: "mini"
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.tdTechServiceCertificationExp.ifPublish
                        ? "公示中"
                        : "未公示"
                    )
                  )
                ]
              ),
              _vm.tdTechServiceCertificationExp.unitLevel
                ? _c(
                    "el-tag",
                    {
                      staticStyle: {
                        "margin-left": "12px",
                        "font-size": "12px"
                      },
                      attrs: { type: "warning", size: "mini" }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.tdTechServiceCertificationExp.unitLevel == 1
                            ? "省级"
                            : _vm.tdTechServiceCertificationExp.unitLevel == 2
                            ? "地市级"
                            : _vm.tdTechServiceCertificationExp.unitLevel == 3
                            ? "区县级"
                            : ""
                        )
                      )
                    ]
                  )
                : _vm._e(),
              _vm.tdTechServiceCertificationExp.levelNo
                ? _c(
                    "el-tag",
                    {
                      staticStyle: {
                        "margin-left": "12px",
                        "font-size": "12px"
                      },
                      attrs: { type: "warning", size: "mini" }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.tdTechServiceCertificationExp.levelNo == "1001"
                            ? "甲级"
                            : _vm.tdTechServiceCertificationExp.levelNo ==
                              "1002"
                            ? "乙级"
                            : _vm.tdTechServiceCertificationExp.levelNo ==
                              "1003"
                            ? "丙级"
                            : ""
                        )
                      )
                    ]
                  )
                : _vm._e(),
              _vm.ifEdit == 1
                ? _c(
                    "el-button",
                    {
                      staticClass: "zwx-button zwx-button-icontext-24",
                      staticStyle: { "margin-left": "12px" },
                      attrs: { icon: "el-icon-edit" },
                      on: { click: _vm.edit }
                    },
                    [_vm._v("编辑")]
                  )
                : _vm._e(),
              _vm.ifEdit == 1
                ? _c(
                    "el-button",
                    {
                      staticClass:
                        "zwx-button zwx-button-icontext-24 el-button--danger",
                      staticStyle: { "margin-left": "12px" },
                      attrs: { icon: "el-icon-delete" },
                      on: {
                        click: function($event) {
                          return _vm.$system.msgbox(
                            "",
                            "提示",
                            "确定要删除吗？",
                            "确定",
                            "取消",
                            _vm.delRecord
                          )
                        }
                      }
                    },
                    [_vm._v("删除")]
                  )
                : _vm._e()
            ],
            1
          ),
          _c("el-descriptions-item", { attrs: { label: "证书编号" } }, [
            _vm._v(_vm._s(_vm.tdTechServiceCertificationExp.certificateNo))
          ]),
          _c("el-descriptions-item", { attrs: { label: "发证日期" } }, [
            _vm._v(
              _vm._s(
                _vm.$system.formatDate(
                  _vm.tdTechServiceCertificationExp.certificateDate,
                  "YYYY-MM-DD"
                )
              )
            )
          ]),
          _c("el-descriptions-item", { attrs: { label: "失效日期" } }, [
            _vm._v(
              _vm._s(
                _vm.$system.formatDate(
                  _vm.tdTechServiceCertificationExp.invalidDate,
                  "YYYY-MM-DD"
                )
              )
            )
          ]),
          _vm.tdTechServiceCertificationExp.logoutDate
            ? _c("el-descriptions-item", { attrs: { label: "注销日期" } }, [
                _vm._v(
                  _vm._s(
                    _vm.$system.formatDate(
                      _vm.tdTechServiceCertificationExp.logoutDate,
                      "YYYY-MM-DD"
                    )
                  )
                )
              ])
            : _vm._e()
        ],
        2
      ),
      _c("hr", { staticClass: "hr-dashed hr-width-96" }),
      _c(
        "div",
        {
          staticStyle: {
            "padding-left": "30px",
            "padding-top": "18px",
            display: "flex"
          }
        },
        [
          _c(
            "div",
            { staticStyle: { "font-size": "14px", "line-height": "18px" } },
            [_vm._v("服务项目：")]
          ),
          _c(
            "div",
            {
              staticStyle: {
                "font-size": "14px",
                "line-height": "18px",
                "max-width": "80%"
              }
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.tdTechServiceCertificationExp.serviceNames) +
                  " "
              )
            ]
          )
        ]
      ),
      _c(
        "div",
        {
          staticStyle: {
            "padding-left": "30px",
            "padding-top": "18px",
            display: "flex"
          }
        },
        [
          _c(
            "div",
            { staticStyle: { "font-size": "14px", "line-height": "14px" } },
            [_vm._v("附件资料：")]
          ),
          _vm.tdTechServiceCertificationExp.tdTechServiceCertificationAnnexExps
            .length > 0 &&
          _vm.tdTechServiceCertificationExp
            .tdTechServiceCertificationAnnexExps[0].annexList
            ? _c(
                "div",
                _vm._l(
                  _vm.tdTechServiceCertificationExp
                    .tdTechServiceCertificationAnnexExps[0].annexList,
                  function(item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticStyle: {
                          "font-size": "14px",
                          "line-height": "14px",
                          color: "#2a50d7 !important",
                          cursor: "pointer",
                          "margin-bottom": "16px"
                        },
                        style:
                          index ==
                          _vm.tdTechServiceCertificationExp
                            .tdTechServiceCertificationAnnexExps[0].annexList
                            .length -
                            1
                            ? "margin-bottom:0;"
                            : ""
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: { display: "inline-block" },
                            on: {
                              click: function($event) {
                                return _vm.openFilePreview(
                                  item.annexName,
                                  item.annexPath
                                )
                              }
                            }
                          },
                          [
                            _c("span", {
                              class: _vm.imgClass(item.annexName),
                              staticStyle: { display: "inline-block" }
                            }),
                            _vm._v(" " + _vm._s(item.annexName) + " ")
                          ]
                        )
                      ]
                    )
                  }
                ),
                0
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }