var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "basic-detail-page" },
    [
      _c(
        "div",
        { staticClass: "title" },
        [
          _c("span", { staticStyle: { "margin-right": "16px" } }, [
            _vm._v(_vm._s(_vm.techServiceBasic.organName))
          ]),
          _vm.ifEdit == 1
            ? _c(
                "el-button",
                {
                  staticClass: "zwx-button zwx-button-icontext-28",
                  attrs: { icon: "el-icon-edit" },
                  on: { click: _vm.edit }
                },
                [_vm._v("编辑")]
              )
            : _vm._e()
        ],
        1
      ),
      _c("hr", { staticClass: "hr" }),
      _c(
        "el-row",
        { staticStyle: { padding: "20px 0px" } },
        [
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-descriptions",
                { staticClass: "basic-descriptions", attrs: { column: 1 } },
                [
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        label: "社会信用代码",
                        "content-class-name": "tech-value-class"
                      }
                    },
                    [_vm._v(_vm._s(_vm.techServiceBasic.creditCode))]
                  ),
                  _c("el-descriptions-item", { attrs: { label: "机构类型" } }, [
                    _vm._v(_vm._s(_vm.techServiceBasic.serviceSortName))
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "注册地区" } }, [
                    _vm._v(_vm._s(_vm.techServiceBasic.zoneName))
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "注册地址" } }, [
                    _vm._v(_vm._s(_vm.techServiceBasic.workAddr))
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-descriptions",
                { staticClass: "basic-descriptions", attrs: { column: 1 } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "法定联系人" } },
                    [_vm._v(_vm._s(_vm.techServiceBasic.legalPerson))]
                  ),
                  _c("el-descriptions-item", { attrs: { label: "联系电话" } }, [
                    _vm._v(_vm._s(_vm.techServiceBasic.telephone))
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "联系人" } }, [
                    _vm._v(_vm._s(_vm.techServiceBasic.linkMan))
                  ]),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "联系人手机" } },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$zwxSm.sm4JsDecrypt(
                            _vm.techServiceBasic.linkMobile
                          )
                        )
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            {
              staticClass: "basic-descriptions last-basic-descriptions",
              attrs: { span: 8 }
            },
            [
              _c(
                "el-descriptions",
                { attrs: { column: 1 } },
                [
                  _c("el-descriptions-item", { attrs: { label: "电子邮箱" } }, [
                    _vm._v(_vm._s(_vm.techServiceBasic.email))
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "公司简介" } }, [
                    _c("div", {
                      class: _vm.imgClass(_vm.techServiceBasic.introduceName)
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "ms-folder-content pointer",
                        on: {
                          click: function($event) {
                            return _vm.openFilePreview(
                              _vm.techServiceBasic.introduceName,
                              _vm.techServiceBasic.introducePath
                            )
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.techServiceBasic.introduceName) + " "
                        )
                      ]
                    )
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "营业执照" } }, [
                    _c("div", {
                      class: _vm.imgClass(_vm.techServiceBasic.licenseName)
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "ms-folder-content pointer",
                        on: {
                          click: function($event) {
                            return _vm.openFilePreview(
                              _vm.techServiceBasic.licenseName,
                              _vm.techServiceBasic.licensePath
                            )
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.techServiceBasic.licenseName) + " "
                        )
                      ]
                    )
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "员工证明" } }, [
                    _c("div", {
                      class: _vm.imgClass(_vm.techServiceBasic.employerName)
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "ms-folder-content pointer",
                        on: {
                          click: function($event) {
                            return _vm.openFilePreview(
                              _vm.techServiceBasic.employerName,
                              _vm.techServiceBasic.employerPath
                            )
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.techServiceBasic.employerName) + " "
                        )
                      ]
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }