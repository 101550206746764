var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "textBook_manage_edit",
      staticStyle: { "padding-bottom": "20px" }
    },
    [
      _c(
        "el-form",
        {
          key: "msForm",
          ref: "msForm",
          staticClass: "zwx-form edit-form",
          attrs: {
            model: _vm.msForm,
            rules: _vm.msFormRules,
            "label-position": "right"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "edit-row" },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "140px",
                    label: "教材名称：",
                    prop: "fileName"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "537px !important" },
                    attrs: {
                      placeholder: "请输入教材名称",
                      clearable: "",
                      maxlength: "200"
                    },
                    model: {
                      value: _vm.msForm.fileName,
                      callback: function($$v) {
                        _vm.$set(_vm.msForm, "fileName", $$v)
                      },
                      expression: "msForm.fileName"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "edit-row" },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "分类：",
                    "label-width": "140px",
                    prop: "categoryCode"
                  }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "zwx-radio-group",
                      staticStyle: { "min-width": "68px!important" },
                      on: { change: _vm.changeCategoryCode },
                      model: {
                        value: _vm.msForm.categoryCode,
                        callback: function($$v) {
                          _vm.$set(_vm.msForm, "categoryCode", $$v)
                        },
                        expression: "msForm.categoryCode"
                      }
                    },
                    [
                      _c(
                        "el-radio",
                        { staticClass: "zwx-radio", attrs: { label: 1 } },
                        [_vm._v("教材")]
                      ),
                      _c(
                        "el-radio",
                        { staticClass: "zwx-radio", attrs: { label: 2 } },
                        [_vm._v("相关资料")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.msForm.categoryCode == 1
            ? _c(
                "div",
                { staticClass: "edit-row" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "专家：", "label-width": "140px" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "zwx-select expertIdList",
                          staticStyle: {
                            width: "537px !important",
                            height: "40px !important"
                          },
                          attrs: {
                            "popper-class": "zwx-select-popper",
                            filterable: "",
                            placeholder: "请选择",
                            clearable: ""
                          },
                          model: {
                            value: _vm.msForm.expertUid,
                            callback: function($$v) {
                              _vm.$set(_vm.msForm, "expertUid", $$v)
                            },
                            expression: "msForm.expertUid"
                          }
                        },
                        _vm._l(_vm.expertNameList, function(item) {
                          return _c(
                            "el-option",
                            {
                              key: item.uuid,
                              staticStyle: { height: "40px!important" },
                              attrs: {
                                label: item.expertName,
                                value: item.uuid
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    float: "left",
                                    "font-size": "13px",
                                    "margin-top": "-7px"
                                  }
                                },
                                [_vm._v(_vm._s(item.expertName))]
                              ),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    float: "left",
                                    display: "block",
                                    position: "absolute",
                                    "margin-top": "10px",
                                    "font-size": "10px"
                                  }
                                },
                                [
                                  _vm._v(
                                    "职称:" + _vm._s(item.professionalTittle)
                                  )
                                ]
                              ),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    float: "right",
                                    color: "#8492a6",
                                    "font-size": "13px"
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$zwxSm.sm4JsDecrypt(item.contact)
                                    )
                                  )
                                ]
                              )
                            ]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.msForm.categoryCode == 1
            ? _c(
                "div",
                { staticClass: "edit-row" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "140px",
                        label: "学时：",
                        prop: "classHour"
                      }
                    },
                    [
                      _c("el-input", {
                        staticClass: "zwx-input",
                        staticStyle: { width: "537px !important" },
                        attrs: {
                          placeholder: "请输入",
                          clearable: "",
                          maxlength: "9"
                        },
                        on: {
                          input: function($event) {
                            return _vm.$validate.number(
                              _vm.$data["msForm"],
                              "classHour",
                              -1,
                              1
                            )
                          }
                        },
                        model: {
                          value: _vm.msForm.classHour,
                          callback: function($$v) {
                            _vm.$set(_vm.msForm, "classHour", $$v)
                          },
                          expression: "msForm.classHour"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.msForm.categoryCode == 2
            ? _c(
                "div",
                { staticClass: "edit-row" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "文件类别：",
                        "label-width": "140px",
                        prop: "fileType"
                      }
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "zwx-radio-group",
                          staticStyle: { "min-width": "68px!important" },
                          attrs: { disabled: _vm.fileTypeDis },
                          model: {
                            value: _vm.msForm.fileType,
                            callback: function($$v) {
                              _vm.$set(_vm.msForm, "fileType", $$v)
                            },
                            expression: "msForm.fileType"
                          }
                        },
                        [
                          _c(
                            "el-radio",
                            { staticClass: "zwx-radio", attrs: { label: 1 } },
                            [_vm._v("pdf")]
                          ),
                          _vm.msForm.categoryCode == 1
                            ? _c(
                                "el-radio",
                                {
                                  staticClass: "zwx-radio",
                                  attrs: { label: 2 }
                                },
                                [_vm._v("视频")]
                              )
                            : _vm._e(),
                          _c(
                            "el-radio",
                            { staticClass: "zwx-radio", attrs: { label: 3 } },
                            [_vm._v("word")]
                          ),
                          _c(
                            "el-radio",
                            { staticClass: "zwx-radio", attrs: { label: 4 } },
                            [_vm._v("excel")]
                          ),
                          _c(
                            "el-radio",
                            { staticClass: "zwx-radio", attrs: { label: 5 } },
                            [_vm._v("ppt")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.msForm.fileType != 2
            ? _c(
                "div",
                { key: "fujian", staticClass: "edit-row" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "140px",
                        label: "附件：",
                        prop: "annexList"
                      }
                    },
                    [
                      _c(
                        "file-upload",
                        {
                          ref: "annexList",
                          attrs: {
                            accept:
                              ".pdf,.PDF,.doc,.docx,.DOC,.DOCX,.xls,.xlsx,.XLS,.XLSX,.ppt,.pptx,.PPT,.PPTX",
                            size: 50 * 1024 * 1024,
                            sizeInfo: "50M"
                          },
                          on: {
                            showFileList: _vm.showannexList,
                            success: _vm.annexFileSuccess,
                            error: _vm.fileUploadError
                          }
                        },
                        [
                          _vm.$zwxBase.verifyIsBlank(_vm.msForm.annexList)
                            ? _c(
                                "el-button",
                                {
                                  staticClass:
                                    "zwx-button zwx-button-icontext-28",
                                  attrs: { icon: "el-icon-upload2" }
                                },
                                [_vm._v(" 上传 ")]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm.$zwxBase.verifyIsNotBlank(_vm.msForm.annexList)
                        ? _c("div", { staticClass: "show-file" }, [
                            _c(
                              "span",
                              {
                                on: {
                                  mouseenter: _vm.annexFileMouseenter,
                                  mouseleave: _vm.annexFileMouseleave
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center",
                                      height: "32px"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "align-items": "center",
                                          color: "#194DFF !important",
                                          cursor: "pointer"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.openFilePreview(
                                              _vm.msForm.annexList,
                                              0
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          class: _vm.$zwxBase.dealfilePrefix(
                                            _vm.msForm.annexList[0].fileName
                                          )
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "name-hidden",
                                            staticStyle: {
                                              "max-width": "400px",
                                              color: "#316CF5!important",
                                              "margin-left": "5px"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.msForm.annexList[0].fileName
                                              )
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "el-upload-list__item check",
                                        style: _vm.msForm.annexList[0].check
                                          ? "margin-left:5px;display:none;"
                                          : "margin-left:5px;cursor:pointer"
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-upload-success el-icon-circle-check"
                                        })
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "check",
                                        style: _vm.msForm.annexList[0].check
                                          ? "margin-left:5px;cursor:pointer"
                                          : "margin-left:5px;display:none;"
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-close",
                                          on: {
                                            click: function($event) {
                                              return _vm.delAnnexFile(0)
                                            }
                                          }
                                        })
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.msForm.fileType == 2,
                  expression: "msForm.fileType == 2"
                }
              ],
              key: "wenjian",
              staticClass: "edit-row"
            },
            [
              _c(
                "div",
                [
                  _vm.showFilePath && _vm.config.videoPlayWay != 2
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            "label-width": "140px",
                            label: "阿里云点播地址：",
                            prop: "filePath"
                          }
                        },
                        [
                          _c("ali-upload-video", {
                            ref: "aliUpload",
                            model: {
                              value: _vm.msForm.filePath,
                              callback: function($$v) {
                                _vm.$set(_vm.msForm, "filePath", $$v)
                              },
                              expression: "msForm.filePath"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm.config.videoPlayWay != 1
                ? _c(
                    "div",
                    { staticClass: "flex-row" },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "font-size": "14px",
                            color: "#3A485E",
                            "padding-left": "10px"
                          }
                        },
                        [_vm._v("上传本地服务器：")]
                      ),
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "top",
                            trigger: "hover",
                            content:
                              "建议上传100M以内的视频，若视频过大建议直接填写视频地址。"
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-info",
                            staticStyle: {
                              color: "#606266",
                              "margin-right": "10px"
                            },
                            attrs: { slot: "reference" },
                            slot: "reference"
                          })
                        ]
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "file-upload",
                            {
                              ref: "localFileRef",
                              attrs: {
                                accept: ".mp4,.MP4",
                                size: 100 * 1024 * 1024,
                                sizeInfo: "100M"
                              },
                              on: {
                                showFileList: _vm.localFileShowFileList,
                                success: _vm.localFileUploadSuccess
                              }
                            },
                            [
                              _vm.$zwxBase.verifyIsBlank(
                                _vm.msForm.annexListLocal
                              )
                                ? _c(
                                    "span",
                                    { staticStyle: { color: "#0045f9" } },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-upload2"
                                      }),
                                      _vm._v(" 上传 ")
                                    ]
                                  )
                                : _vm._e()
                            ]
                          ),
                          _vm.msForm.localFilePath
                            ? _c(
                                "div",
                                { staticClass: "show-file" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass:
                                        "zwx-button zwx-button-text-26",
                                      attrs: { type: "text" },
                                      on: {
                                        click: function($event) {
                                          return _vm.openVideoPreview(
                                            _vm.msForm.localFilePath
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("查看")]
                                  ),
                                  _vm.msForm.localFilePath
                                    ? _c(
                                        "el-button",
                                        {
                                          staticClass:
                                            "zwx-button zwx-button-text-26",
                                          attrs: { type: "danger" },
                                          on: {
                                            click: function($event) {
                                              return _vm.deletionLocalFile1(0)
                                            }
                                          }
                                        },
                                        [_vm._v("删除")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.config.videoPlayWay != 1
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "140px",
                        label: "服务器存储地址：",
                        prop: "storePath"
                      }
                    },
                    [
                      _c("el-input", {
                        staticClass: "zwx-input",
                        staticStyle: { width: "537px !important" },
                        attrs: {
                          placeholder: "请输入文件地址",
                          clearable: "",
                          maxlength: "200"
                        },
                        model: {
                          value: _vm.msForm.storePath,
                          callback: function($$v) {
                            _vm.$set(_vm.msForm, "storePath", $$v)
                          },
                          expression: "msForm.storePath"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ]
      ),
      _c(
        "fixed-footer",
        [
          _c(
            "el-button",
            {
              staticClass: "zwx-button zwx-button-32",
              attrs: { type: "primary", disabled: _vm.saveButtonDisabled },
              on: { click: _vm.addOrModifyExamConfig }
            },
            [_vm._v("保存")]
          )
        ],
        1
      ),
      _c(
        "base-dialog",
        {
          ref: "chooseTopicDialogRef",
          staticClass: "choose-topic-dialog",
          attrs: { title: "选择题目" },
          on: {
            determine: _vm.determineChooseTopicDialog,
            cancel: _vm.cancelChooseTopicDialog,
            close: _vm.cancelChooseTopicDialog
          }
        },
        [
          _c("div", { staticClass: "choose-topic-body" }, [
            _c("video", {
              ref: "video",
              attrs: { src: "", id: "video", controls: "" }
            })
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }